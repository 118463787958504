<template>
  <section id="login">
    <a @click="step = 1" v-if="step > 1" class="clean_btn go_back" >{{'➝' + get_word('back')}}</a>
    <div class="wrapper">
      <sendOtp v-if="step === 1" :phoneNumber="phoneNumber" @success="successOtpSend" />
      <validateOtp v-if="step === 2" :phoneNumber="phoneNumber" />
    </div>
  </section>
</template>

<script>
import SendOtp from '@/components/OTP/SendOtp.vue'
import ValidateOtp from '@/components/OTP/ValidateOtp.vue'
export default {
  components: { SendOtp, ValidateOtp },
    data() {
        return {
          step: 1,
          phoneNumber: ''
        }
    },
    methods: {
      successOtpSend(phone){
        this.phoneNumber = phone;
        this.step++;
      }
    }
}
</script>

<style lang="scss">
    #login {
      &{display: flex;min-height: 100vh;padding-top: 40px;flex-direction: column;align-items: center;gap: 20px;}
      .go_back{background-color: transparent;margin-inline-end: auto;margin-inline-start: 10px;font-size: 18px;}
      .wrapper{
        &{border-radius: 20px;width: 80%;}

        .form {
          &{text-align: center;display: flex;flex-direction: column;align-items: center;}
          
          h2, .title{line-height: 1;margin-bottom: 15px;text-align: center;font-size: 28px;}

          form {
            &{display: flex;flex-direction: column;gap: 10px;width: 100%;}
            label{
              &{display: block;margin-bottom: 10px;font-size: 20px;}
              &::after{content:":"}
              }

          }

          .btn_wrapper{
            &{display: flex; gap: 20px;justify-content: center;}
            .btn_primary{padding: 10px 20px;}
          }
        }
        
      }

      .login_signup_toggle {
            button{color: royalblue;font-size: 18px;text-decoration: underline;}
      }
    }
</style>