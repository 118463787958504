<template>
  
  <header id="header" :key="$route.matched[0].path">
    <div id="header_inner">
    <router-link to='/'  id="logo" class="title_cls"></router-link>
<!--     
    <router-link id="go_back_btn" v-if="$routerHistory.hasPrevious() && $route.matched[0].props.default.hide_back !== true" :to="{ path: $routerHistory.previous().path }">חזרה</router-link>
    <router-link id="go_back_btn" v-if="!$routerHistory.hasPrevious() && $route.matched[0].props.default.hide_back !== true" :to="{ path: '/' }">חזרה</router-link>
     -->

     <!-- <div id="user_side" v-if="user" >
      <span v-text="'היי ' + user.display_name"></span>
    </div> -->
    </div>

    

    </header>
</template>

<script>
//import search from './search.vue'

export default {
  props:["user"],
    components: {
      //'search': search,
    },
  data() {
    return {
      //is_search_opened: false,
      
    };
  }
}
</script>

<style lang="scss" scoped>
#user_side { display: flex; gap:15px; align-items: center;}
</style>