<template>

    <div></div>
</template>

<script>

export default {
    data(){
        return {
            item: false
        }
    },
    mounted() {

        this.api({ action: 'pages/get', data: {id: 50}, method: 'get'}, (data) => {
            
            this.item = data.data;

            //this.$emit('loaded');
        });

    },
}

</script>