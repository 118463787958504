<template>
    <div class="box" v-if="loaded">
    
      <div class="line">
        <i class="fas fa-map-marker-alt"></i>
        <div class="content">

          <div id="mikve_select_wrapper">
            <h2 v-html="get_word('choose_mikve')"></h2>
            {{mikveName}}
            <button style="margin-right: 10px;font-size:18px;display:block;margin: 10px auto 0" class="btn" v-html="get_word('change_mikve')" @click="isChangeMikve = true; "></button>

            <mikveList v-if="isChangeMikve" @close="isChangeMikve = false" v-model:mikveId="orderData.selectedMikve" v-model:mikveName="mikveName" />

          </div>

        </div>
      </div>
    

      <div class="line last">
        <i class="fas fa-table"></i>
        <div class="content">

          <div id="date_pick_wrapper">
            <h2 v-html="get_word('choose_date')"></h2>
            <VueDatePicker  v-model="orderData.selectedDate" auto-apply format="dd/MM/yyyy" @update:model-value="onSelectDate" week-start="0"  :min-date="currentDate"  :day-names="['א', 'ב', 'ג', 'ד', 'ה', 'ו', 'ז']"  locale="he" cancelText="ביטול" selectText="בחרי" ></VueDatePicker>
          </div>

        </div>
      </div>
      <div style="padding-bottom: 50px;"></div>
      
      <button class="btn" id="big_btn" @click="go_to_order" v-html="get_word('choose_hour')"></button>

    </div>
  </template>
  
  <script>
  import mikveList from '@/views/mikveList.vue';

  export default {
    components:{
      mikveList
    },
    
    data() {
  
      return {
        currentDate:'',
        id: this.$route.params.id,
        order: {},
        orderData: {
          selectedMikve: '',
          selectedDate: new Date().toISOString().slice(0,10),
          orderType: 1
        },
        
        mikve_data:{},
        mikveName:'',
        loaded:false,
        isChangeMikve: false,
        selectedMikveDetails: {},
        mikves: [],
        selectedMikveDates: [],
        settingsMikve: {
          language: {
              noResults(){ return "לא נמצאו תוצאות";},
              searching() { return "מחפש.."; }
          },
          dir: "rtl",
        },
      }
    },
    methods: {
      onSelectMikve(selectedMikve) {
        this.api({action: 'mikve/get',data: { id: selectedMikve.id } }, (data)=>{
          this.selectedMikveDetails = data.data;
          this.getDates();
        });
      },
      onSelectDate(event){
        console.log(event)
      },
      getDates() {
        this.api({action: 'get_dates' }, (data)=>{
          this.selectedMikveDates = data.data;
        });
      },
      go_to_order() {
        this.$router.push(`/edit/${this.id}/${this.orderData.selectedMikve}/${this.orderData.selectedDate}/${this.orderData.orderType}`)
      },
    },
    mounted() {
      this.currentDate = new Date();

      this.currentDate.setDate(this.currentDate.getDate() - 1);

      this.api({ action: 'get_mikves', method: 'get'}, (data)=>{
        this.mikves = data.data.items;
          this.loaded = true;
      })

      this.api({ action: 'get_order', data: {id: this.id}}, (data)=>{
        this.order = data.data.order;
        this.mikve_data = data.data.order.mikve_data;
        this.mikveName = this.mikve_data.city+' - '+this.mikve_data.address;
        this.orderData = {
          selectedMikve: this.order.mikve_id,
          selectedDate: new Date(this.order.apt_date).toISOString().slice(0,10),
          orderType: this.order.type_id,
        };
      })
      // setTimeout(() => {  document.getElementById('date_inp').valueAsDate = new Date();}, 200);

    },
}
</script>