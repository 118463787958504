<template>
  <div id="order_page" class="box" v-if="loaded">
        <h1>ההזמנה בוצעה בהצלחה!</h1>
        <ul class="details">
            <div class="small_title">מועד התור</div>
            <li><i class="fas fa-table"></i>
                <span v-text="date_parsed"></span>
                <div class="wrapper">
                    <a target="_blank" :href="link">הוספה ליומן</a>
                </div>
            </li>
           
            <div class="small_title"> מיקום</div>
            <li><i class="fas fa-map-marker-alt"></i>
                <span v-text="mikve.name"></span>
                 <!-- <div style="display: flex;gap: 10px;" class="wrapper">
                    <p >לניווט:</p>
                    <div class="links">
                        <div>
                            <a target="_blank" class="btn_white" :href="waze"><i class="fab fa-waze"></i> </a>
                        </div>
                        <div>
                            <a target="_blank" class="btn_white" :href="googleMaps"><i class="fas fa-map"></i> </a>
                        </div>
                    </div>
                 </div> -->
            </li>
            <div class="small_title"> הערות נוספות</div>
            <li><i class="fas fa-clipboard"></i>
                <span v-html="get_word('what_to_bring')"></span>
                 <!-- <div style="display: flex;gap: 10px;" class="wrapper">
                    <p >לניווט:</p>
                    <div class="links">
                        <div>
                            <a target="_blank" class="btn_white" :href="waze"><i class="fab fa-waze"></i> </a>
                        </div>
                        <div>
                            <a target="_blank" class="btn_white" :href="googleMaps"><i class="fas fa-map"></i> </a>
                        </div>
                    </div>
                 </div> -->
            </li>
             <!-- <div class="small_title"> סוג טבילה</div> -->
            <!-- <li><i class="fas fa-scroll"></i><span v-text="orderData.type_parse"></span></li> -->
        </ul>
        <a href="/" class="btn">לדף הבית</a>
  </div>
</template>

<script>
export default {
    data() {
        return {
            orderId: this.$route.params.id,
            mikve: false,
            link: false,
            paymentId: 0,
            loaded: false,
            waze: '',
            googleMaps: '',
            paymentMethod: false,
            orderData: [],
            date_parsed: false,
        }
    },
    mounted(){
     
      this.api({action: 'success_page', data: { order_id: this.orderId } }, (data)=>{
        this.mikve = data.data.mikve;
        this.link = data.data.link;
        this.googleMaps = data.data.google_maps_link;
        this.waze = data.data.waze_link;
        this.date_parsed = data.data.date_parsed;
        this.loaded = true;
        this.orderData = data.data.order;
      });

    },
    computed: {
      
    },
    methods:{
   
    }
}
</script>

<style lang="scss" scoped>
#order_page {
    .wrapper{    margin-right: 32px;}
    .links{    display: flex;   justify-content: flex-start;   gap: 15px;
        a{text-decoration: none;display: flex;justify-content: center;align-items: center; font-size: 15px !important;   height: auto;    border-color: #000 !important;color: #000 !important;padding: 0 5px;}
        .svg-inline--fa {color: #000 !important;font-size: 16px;}
    }
    .btn{width: 100%;margin: 30px 0 0;}
    &{padding-top: 40px;}
    h1{text-align: center;margin-bottom: 10px;}
   
    .details { list-style: none; margin-bottom: 15px;
        li { display: flex; gap:10px; align-items: center;
            i, svg { color: var(--color1); width: 20px;font-size: 20px;vertical-align: sub; }
            label { display: block; font-weight: bold;
                &::after { content: ":"; }
            }
            span { font-weight: 400;margin-right: 10px;}
        }
    }
    .small_title{font-weight: 600; margin-right: 30px;}
    li:not(:last-child){ border-bottom: 1px solid rgba(0,0,0,0.1);;padding-bottom: 10px;margin-bottom: 5px;}
    li{display: block !important;}
    li a{display: block;font-size: 16px;text-decoration: underline;}
    .summary { font-size: 30px; text-align: center; font-weight: bold; margin: 15px 0;}

    .btns_wrapper{display: flex;justify-content: center;gap: 20px; gap:10px;
        & > div { flex:1; text-align: center;
            button { width:100%; font-size: 26px;}
            div { font-size: 16px;}
        }
    }
    iframe{width: 100%;border: none;outline: none;height: 700px; }

    .packages { 

        ul { list-style: none; border-radius: 10px; overflow: hidden;
            li { padding: 10px; background: #eee; border-bottom: 1px solid rgba(0,0,0,0.3); display: flex; justify-content: space-between; gap:15px; align-items: center;
                &.active { background: var(--color_dom); color:#fff;}
                h4 { font-weight: normal;}
                span { font-weight: bold;}
            }
        }
    }
}
</style>