<template>
    <div id="shop">
        <template  v-if="paymentId == 0 || !paymentId">
        <div class="item box" v-for="item in items" :key="item.id">
            <div class="pic">
                <span :style="bg(item.pic_parsed)"></span>
            </div>
            <div class="content">
                <h3 v-text="item.name"></h3>
                <span class="price" v-text="'₪' + item.price"></span>
                <div class="description" v-html="item.description"></div>
                <div style="text-align: center;">
                    <a class="btn" v-if="!item.in_basket" @click="item.in_basket=true">הזמנה</a>
                    <div v-else class="number_inp">
                        <a @click="add(item)" :class="{disabled: item.qty >= item.max_qty}"><i class="fas fa-plus-circle"></i></a>
                        <span v-text="item.qty"></span>
                        <a @click="remove(item)"><i class="fas fa-minus-circle"></i></a>
                    </div>
                </div>
            </div>
        </div>
        <div id="basket" :class="{active: showBasket}">
            <div class="details">
                <div v-for="item in basketItems" :key="item.id" v-text="item.qty + ' x ' + item.name"></div>

            </div>
            <div style="text-align: center;">
                <div style="font-weight: bold;" v-text="'סכום: ₪' + basketTotal"></div>
            </div>
            <div class="btns_wrapper">
                    <!-- <div>
                        <button class="btn" @click="order('bit')">{{ get_word('pay_bit') }} <img src="../assets/Bit_logo.svg" style="height:20px" alt="" /></button>
                    </div> -->
                    <div>
                        <button class="btn" @click="order('payme')">{{ get_word('pay_credit') }} <i class="far fa-credit-card"></i></button>
                    </div>
                     <div>
                         <button class="btn" @click="order('cash')">{{ get_word('pay_cash') }} <i class="far fa-money-bill-alt"></i></button>
                    </div>
                </div>
               
        </div>
        </template>
        <template v-if="paymentId > 0">
            <h1>תשלום</h1>
            <iframe :src="'https://api.mikve.online/payments/do_redirect/'+ paymentId"></iframe>
        </template>
    </div>
</template>

<script>

export default {
    data(){
        return {
            items: [],
            orderID: this.$route.params.orderID,
            paymentId:0
        }
    },
    mounted() {

         window.addEventListener('message', this.handlePaymentEvent, true); 

        this.api({ action: 'shop/results', data: {order_id: this.orderID}}, (data) => {
            
            data.data.items.map((item) => {
                item.in_basket = false;
                item.qty = 1;
                return item;
            });

            this.items = data.data.items;

            //this.$emit('loaded');
        });

    },
    computed:{
        showBasket(){

            let ret = false;

            for (let i in this.items)
            {
                if (this.items[i].in_basket){
                    // this.scrollDown();
                    return true;
                } 
            }

            return ret;
        },
        basketItems(){

            let ret = [];

            for (let i in this.items)
            {
                if (this.items[i].in_basket) ret.push(this.items[i]);
            }

            return ret;
        },
        basketTotal(){

            let total = 0;

            for (let i in this.items)
            {
                if (this.items[i].in_basket) total += this.items[i].price * this.items[i].qty;
            }

            return total;
        }
    },
    methods:{
        handlePaymentEvent(event)
        {
            if (event.data == 'payment_completed')
            {
                this.alert('המוצרים הוזמנו בהצלחה!');
                this.$bus.$emit('reload');
            }
        },
         scrollDown(){
            setTimeout(() => {
            document.getElementById('shop').scrollIntoView({ behavior: "smooth" });
            }, 100);
        },
        order(provider = 'payme'){
            if(this.basketTotal < 5)
            {
                this.alert('אין אפשרות לבצע הזמנה מתחת ל5 ש"ח!');
                return false;
            }
            let items = [];
            for(let i in this.basketItems){
                if(this.basketItems[i].qty >= 1){
                    items.push({item_id:this.basketItems[i].id,qty:this.basketItems[i].qty})
                }
            }
            this.api({ action: 'add_shop_ord', data: {provider:provider,order_id:this.orderID,items,user_id:this.$store.user.id}}, (data)=>{
                if (data.data) {
                    if (provider == "cash")
                    {
                        this.$bus.$emit('reload');
                        this.alert('המוצרים הוזמנו בהצלחה!');
                        window.removeEventListener('message', this.handlePaymentEvent, true);
                    }
                    else 
                    {
                        this.paymentId = data.data;
                    }
                }
            });
        },
        add(item){
            if (item.max_qty > item.qty) item.qty++;
        },
        remove(item){
            if (item.qty == 1) 
            {
                item.in_basket = false;
            }
            else item.qty--;
        }
    }
}

</script>

<style lang="scss" scoped>

$basket_height: 160px;

#shop{ padding-bottom: $basket_height;
    .item { margin-bottom: 15px; overflow: hidden;
        .pic { 
            span { display: block; padding-bottom: 50%; background-size: cover; margin: -15px -20px 15px -20px; }
        }
    }
}

#basket {    overflow-y: scroll; height: $basket_height; background: #fff; border-top: 4px solid var(--color_dom); position: fixed; bottom: 0px; left: 0px; right: 0px; padding: 15px; display: flex; gap:10px; justify-content: space-between;flex-wrap:wrap;
    
    &:not(.active) { display: none; }
    &.active { 
        -webkit-animation: slide-top 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
        animation: slide-top 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
    }

    .details { font-size: 16px;}
    .btns_wrapper{
        .svg-inline--fa {    font-size: 19px;}
        &{flex-basis: 100%;display: flex;justify-content: center;gap: 5px;}
        div{flex: 1;
            button{    display: flex;align-items: center; font-size: 14px; padding: 0 13px; width: 100%; line-height: 1; align-content: center; height: 65px; flex-direction: column-reverse; padding-top: 10px;border-radius: 10px;}
        }
    }
}

.number_inp {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    background: var(--color_dom);
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100px;
    gap: 10px;
    height:48px;
}

@media (min-width: 600px) {
    .number_inp {
        width:150px;
        padding: 20px 15px
    }
}

@media (max-width: 600px) {
    .number_inp {
        width:100px;
        padding: 0px 10px;
        margin: 0 auto
    }
}

.number_inp a { color: #fff; cursor: pointer; font-weight: bold;
    &.disabled { opacity: 0.6;}
}

@media (min-width: 600px) {
    .number_inp a {
        font-size:30px
    }
}

@media (max-width: 600px) {
    .number_inp a {
        font-size:20px
    }
}

@media (min-width: 600px) {
    .number_inp span {
        font-size:30px
    }
}

@media (max-width: 600px) {
    .number_inp span {
        font-size:20px
    }
}


@-webkit-keyframes slide-top {
  0% { -webkit-transform: translateY(0); transform: translateY( $basket_height ); }
  100% { -webkit-transform: translateY(0px); transform: translateY(0px); }
}
@keyframes slide-top {
  0% { -webkit-transform: translateY(0); transform: translateY( $basket_height ); }
  100% { -webkit-transform: translateY(0px); transform: translateY(0px); }
}
    iframe{width: 100%;border: none;outline: none;height: 700px; }

</style>