<template>
    <div id="pages" v-if="loaded">
        <div v-html="content"></div>
    </div>
</template>

<script>

export default {
    components:{},
    data(){
        return {
            loaded:false,
            content:''
        }
    },
    mounted() {

         this.api({ action: 'get_terms'}, (data)=>{
                if (data.data) {
                     this.content = data.data;
                     this.loaded = true;
                }
            });
       

    },
    computed:{
      
    },
    methods:{
       
       
    }
}

</script>

<style lang="scss" scoped>
#pages{
    &{padding: 10px 20px;}
}
</style>