<template>
    <div class="form">
        <h2 class="title" v-html="get_word('insert_otp')"></h2>
        <form class="form_steps" @submit.prevent="validateOtp()">
            <div class="form-item">
                <label for="otp" v-html="get_word('my_otp_is')"></label>
                <input input-mode="numeric" type="number" class="styled_input" id="otp" v-model="otp" autocomplete="one-time-code" required/>
            </div>
            <div class="btn_wrapper">
                <!-- <button type="button" class="btn_primary clean_btn" :disabled="allowCodeSend" @click="startCountdown">
                  <vue-countdown v-if="allowCodeSend" :time="3000" @end="onCountdownEnd" v-slot="{ totalSeconds }">{{ get_word('send_more_code') }} {{ totalSeconds }}</vue-countdown>
                  <span v-else >{{get_word('send_code')}}</span>
                </button> -->
                <input type="submit" class="btn_primary clean_btn" :value="get_word('proceed')">
            </div>
        </form>
    </div>
</template>
<script>
// import VueCountdown from '@chenfengyuan/vue-countdown';

export default {
  // components: { VueCountdown },
  props: {
    
    phoneNumber: {
      required: true,
      type: String,
    },
    
  },
    data() {
        return {
            allowCodeSend:true,
            otp:'',
        }
    },
    methods:{
      startCountdown(){
        this.$emit('sendOtp');
        this.allowCodeSend = true;
      },
      onCountdownEnd() {
        this.allowCodeSend = false;
      },
      validateOtp(){

			var phone = '';
			this.allow_code_send = false;
			phone = this.phoneNumber;
			
			this.api({ action: 'validate_otp', data:{ identification:phone ,otp: this.otp }}, (data) => {
					if(data.data === false) {
						this.alert(this.get_word('wrong_code'));
					}	else {
              this.set_user_token(data.data);
              //if (this.isApp) this.afterLogin();
              this.$bus.$emit('reload');
					}
      });
		},
    }
}
</script>