<template>
	<div id="mikve_info" v-if="loaded">
		<div class="paddme gap_bottom">
			<div>
				<h1>{{data.name}}</h1>
				<div >
					<span style="font-weight: bold;" v-if="mikve_name && mikve_name !=''">{{mikve_name}}</span>
					<div class="btns" style="display: flex; align-items: center; justify-content: center;gap:10px">
						<!-- <router-link :to="'/inbox/' + data.id" class="btn_white"><i class="fas fa-comments"></i> כתבי למקווה </router-link> -->
						<!-- <button @click="showMikeList = true" class="btn" v-html="get_word('change_mikve')"></button> -->
						
					</div>
					

				</div>
				
				<mikveList v-if="showMikeList" @close="showMikeList = false" v-model:mikveId="mikve_id" v-model:mikveName="mikve_name" @change="load_data" />

			</div>
		</div>
		<div class="box" style="overflow: hidden;">
			
			<div style="margin: -15px -20px 15px -20px;">
				<img :src="data.pic_parsed" style="width:100%;" >
			</div>
			<ul>
				<li v-if="data.name">
					<label v-text="get_word('mikve_addr')"></label>
					<div v-html="data.name"></div>
				</li>
				<li v-if="data.address">
					<label v-text="get_word('address')"></label>
					<div v-html="data.address"></div>
				</li>
				<li v-if="data.phone">
					<label v-text="get_word('phone_balanit')"></label>
					<div v-html="data.phone"></div>
				</li>
				
				<li v-if="data.notes">
					<label v-text="get_word('notes')"></label>
					<div v-html="data.notes"></div>
				</li>
			
			
			</ul>
		</div>
	</div>
</template>
<script>
import mikveList from '@/views/mikveList.vue';
export default {
	components: {mikveList},
	data() {
		return {
			loaded: false,
            showMikeList: false,
			data: [],
			mikve_id: this.$store.user.mikve_id,
			mikve_name: '',
			workers: [],
		};
	},
	mounted() {
		this.load_data();
	},
	methods: {
		load_data(){
			this.api({ action: "get_mikve_info",data:{mikve_id:this.mikve_id} }, (data) => {
				this.data = data.data;
				this.workers = data.data.workers;
				this.mikve_id = data.data.id;
				this.mikve_name = data.data.name;
				this.loaded = true;
			});
		},
		save() {
			this.api({ action: "settings_save", data: { form: this.form } }, () => {
				this.alert("הפרטים נשמרו בהצלחה");
			});
		},
	},
};
</script>
<style lang="scss" scoped>
h1{color: #000;margin-bottom: -10px;}
#mikve_info{
    ul{
		li { margin-bottom: 15px;
			label { font-weight: bold;}
		}
    }
   
}
</style>
