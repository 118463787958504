<template>
<div>
    <div class="form">
        <h2 class="title" v-html="get_word('login_with_phone')"></h2>
        <form class="form_steps">
            <div class="form-item">
                <label for="phone" v-html="get_word('my_number_is')"></label>
                <input  class="styled_input" type="text" id="phone" v-model="identification" autocomplete="off" required/>
            </div>
            <div class="btn_wrapper">
                <input type="submit" @click="sendOtp" class="btn_primary clean_btn" :value="get_word('otp_sms')"/>
            </div>
        </form>
    </div>
    </div>
</template>
<script>
// import Popup from "@/views/popup.vue"
export default {
    components:{},
  props: {
    phoneNumber: {
      required: true,
      type: String,
    },
    
  },
    data() {
        return {
            identification:'',
            isApprove:false,
            showDialog:false,
            showPrivacy:false,
            terms:'',
            privacy:'',
        }
    },
    mounted(){
        if(this.phoneNumber != '') this.identification = this.phoneNumber;
        
        
            
    },
    methods:{
        sendOtp(ev,type='SMS')
		{
            if (!this.identification) return this.alert('יש להזין מספר טלפון', '', 'אישור')
            ev.preventDefault();
            
            let identification = this.identification;
            
			this.api({ action: 'signup' , data: { identification:identification,type }}, (data) => {
                if(data.data == "mail_not_valid")
                {
                    this.alert(this.get_word('_EMAIL_IS_INVALID_'));
                }
                else if(!data.data)
                {
                    this.alert(this.get_word('wrong_code'));
                }
                else{
                     this.$emit("success" , identification);
                }
            });
		},
    }
}
</script>
<style lang="scss" scoped>

</style>