<template>
  <div id="order_page" class="box" v-if="loaded">
        
        <h1>פרטי ההזמנה</h1>
        <ul class="details">
            <li><i class="fas fa-table"></i><span v-text="date_parsed"></span></li>
            <li><i class="fas fa-map-marker-alt"></i><span v-text="mikve.name"></span> </li>
        </ul>
        <div>
            <p style="color:#000;margin:15px 0;text-align:center" v-html="get_word('what_to_bring')"></p>
            <div class="packages">
                <div class="btns_wrapper">
                    <div>
                        <button class="btn" @click.once="order()">אישור</button>
                        <!-- <div>{{ get_word('pay_credit') }}</div> -->
                    </div>
                </div>
            </div>
        </div>

    <!-- <template v-if="paymentId > 0">
        <h1>תשלום</h1>
        <iframe :src="'https://api.mikve.online/payments/do_redirect/'+ paymentId"></iframe>
    </template> -->

  </div>
</template>

<script>
export default {
    data() {
        return {
            // orderId: this.$route.params.id,
            mikveID: this.$route.params.mikve_id,
            mikve: false,
            apt_loading: false,
            date: this.$route.params.date,
            type: this.$route.params.type.split("+")[0],
            paymentId: 0,
            loaded: false,
            paymentMethod: false,
            packages: [],
            optSelected: false,
            curr_package: false,
            date_parsed: false,
        }
    },
    mounted(){

   
      this.api({action: 'get_preorder_details', data: { mikve_id: this.mikveID, type: this.type, date: this.date } }, (data)=>{
        this.mikve = data.data.mikve;
        this.date_parsed = data.data.date_parsed;
        this.loaded = true;
    
      });


    },
    computed: {
        orderType(){
            for (let i in this.$store.order_types)
            {
                if (this.type == this.$store.order_types[i].id) return this.$store.order_types[i].name;
            }

            return '';
        },
        totalComp(){
            if (this.optSelected === false) return this.total;
            else 
            {
                for (let i in this.packages)
                {
                    if (this.packages[i].id == this.optSelected) return this.packages[i].price;
                }
            }

            return 0;
        }
    },
    methods:{

        order(){

            if(this.apt_loading){
                return false
            }
            this.apt_loading = true;

            let data = {mikve_id: this.mikve.id, date: this.date, type: this.type, package_id: this.optSelected };

            this.api({ action: 'add_apt', data: data}, (data)=>{
                if (data.data) {
                    
                        let link = '/success_page/'+data.data;
                        this.apt_loading = false;
                        this.$router.push(link);
                }
            });
        },

     
    
    }
}
</script>

<style lang="scss">
#order_page {
    &{padding-top: 40px;}
    h1{text-align: center;margin-bottom: 10px;}
   
    .details { list-style: none; margin-bottom: 15px;
        li { display: flex; gap:10px; align-items: center;
            i, svg { color: var(--color1); width: 20px; }
            label { display: block; font-weight: bold;
                &::after { content: ":"; }
            }
            span { font-weight: 400;}
        }
    }

    .summary { font-size: 30px; text-align: center; font-weight: bold; margin: 15px 0;}

    .btns_wrapper{display: flex;justify-content: center;gap: 20px; gap:10px;
        & > div { flex:1; text-align: center;
            button { width:100%; font-size: 26px;}
            div { font-size: 16px;}
        }
    }
    iframe{width: 100%;border: none;outline: none;height: 700px; }

    .packages { 

        ul { list-style: none; border-radius: 10px; overflow: hidden;
            li { padding: 10px; background: #eee; border-bottom: 1px solid rgba(0,0,0,0.3); display: flex; justify-content: space-between; gap:15px; align-items: center;
                &.active { background: var(--color_dom); color:#fff;}
                h4 { font-weight: normal;}
                span { font-weight: bold;}
            }
        }
    }
}
</style>