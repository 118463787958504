<template>
  <section id="user_settings" class="box" v-if="loaded">
    <h1 class="dashboard_title" v-html="get_word('user_settings')"></h1>
    <ul class="setting_list">
        <!-- <li class="secondary">
            <label for="record_calls_mail" v-html="get_word('_EMAIL_')"></label>
            <input :placeholder="get_word('insert_mail')" required type="email" v-model="settings.username">
        </li> -->

        <li class="secondary">
            <label for="record_calls_mail" v-html="get_word('_NAME_')"></label>
            <input :placeholder="get_word('insert_mail')" required type="text" v-model="settings.display_name">
        </li>
         <li class="secondary" >
            <label for="phone" v-html="get_word('phone')"></label>
            <input id="phone" required type="text" v-model="settings.phone">
        </li>
        <li class="secondary" >
            <label for="email" >אימייל</label>
            <input id="email" required type="text" v-model="settings.username">
        </li>
        <!-- <li class="secondary" v-if="settings.is_bride ">
            <label v-html="get_word('wedding_card')"></label>
            <div class="add" >
                <label v-text="get_word('bride_pic')"></label>
                <div class="pic_wrapper">
                    <div class="pic" :style="settings.bride_pic != '' && !pic_loaded  ? 'background-image:url(' + settings.bride_pic + ')'  : 'background-image:#ccc'">
                        <input  id="fileUpload"   type="file"   ref="file"   hidden   @change="uploadFile"/>
                        <div v-show="pic_loaded" class="progress-bar">
                            <div class="progress-fill"></div>
                        </div>
                    </div>
                    <div class="wrapper">
                        <div v-show="!pic_loaded && settings.bride_pic == ''">
                            <a @click="chooseFiles()">
                                {{ get_word("add_pic") }} <i class="fas fa-plus"></i
                            ></a>
                        </div>
                        <div v-show="!pic_loaded && settings.bride_pic != ''">
                            <a @click="chooseFiles()">
                                {{ get_word("change_pic") }} <i class="fas fa-edit"></i
                            ></a>
                        </div>
                    </div>
                </div>
            </div>


        </li> -->

        <!-- <li class="secondary">
            <label for="record_calls_mail">תאריך חתונה</label>
            <input type="date" v-model="settings.wedding_date">
        </li> -->
<!-- 
        <li class="secondary">
            <label for="record_calls_mail" v-html="get_word('favorite_mikve')"></label>
            <div class="wrapper">
                <span v-if="settings.mikve_name && settings.mikve_name !=''">{{settings.mikve_name}}</span>
                <button @click="showMikeList = true" class="btn" v-html="get_word((settings.mikve_name && settings.mikve_name !='' ? 'change' :'choose'))"></button>
            </div>
            
            <mikveList v-if="showMikeList" @close="showMikeList = false" v-model:mikveId="settings.mikve_id" v-model:mikveName="settings.mikve_name" />
        </li> -->

        <button @click="saveSettings" class="btn" v-html="get_word('save')"></button>
    </ul>

  </section>
</template>

<script>
// import mikveList from '@/views/mikveList.vue';
import axios from "axios";

export default {
    components:{},
    data() {
        return {
            loaded: false,
            settings: {},
            pic_url:'',
            pic_loaded: false,
            showMikeList: false,
            mikves: [],
            settingsMikve: {
                language: {
                    noResults(){ return "לא נמצאו תוצאות";},
                    searching() { return "מחפש.."; }
                },
                dir: "rtl",
            },
        }
    },
    mounted(){

        this.api({ action: 'get_settings'}, (data)=>{
            this.settings = data.data;
            this.loaded = true;
        });
 
    },
    computed: {
        words() {
        return this.$store.state.words
        },
        user() {
        return this.$store.state.user
        },
    },
    methods: {
        saveSettings() {
            this.api({action: 'save_settings',data: this.settings }, ()=>{
                this.$swal(this.get_word('saved_changes'));

                this.$bus.$emit('reload');
            });
        },
        chooseFiles() {
			document.getElementById("fileUpload").click();
		},
		uploadFile(e) {
			let formData = new FormData();

			var headers = {
				"Content-Type": "multipart/form-data",
				Accept: "application/json",
			};

			this.pic_loaded = true;
			formData.append("file", e.target.files[0]);
			axios
				.post(window.api_url + "upload_pic", formData, {
					/*emulateJSON:true,*/ headers: headers,
				})
				.then(
					(response) => {
						if (response.data == "error") {
							this.alert("קרתה שגיאה נא לנסות שנית");
						}
						this.settings.pic = response.data.data.id;
						this.settings.bride_pic = response.data.data.url;
						this.pic_loaded = false;
					},
					(response) => {
						console.error(response.body);
					}
				);
		},
        onSelectMikve(selectedMikve) {
            this.api({action: 'mikve/get',data: { id: selectedMikve.id } }, (data)=>{
                this.mikve = data.data;
            });
        },
    },
}
</script>

<style lang="scss">
#user_settings {
     .pic_wrapper{display: flex;align-items: center; gap: 20px;}
    .pic{background: #ccc;height: 150px;background-position: center;background-size: cover;width: 150px;display: flex;justify-content: center;align-items: center;font-size: 30px;border-radius: 10px;position: relative;}

    .bride_pic{height: auto;width: 100%;object-fit: contain;object-position: center;}
    .wrapper{display: flex;align-items: center;justify-content: space-between;}
    h1{font-size: 32px;text-align: center;margin-bottom: 20px;}
    .setting_list {
        &{display: flex;flex-direction: column;gap: 20px;width: 100%;}

        li {
            &{display: flex;}
            &:not(:last-child){border-bottom: 1px solid #ccc;padding-bottom: 15px;}
            &.main{justify-content: space-between;align-items: center;}
            &.secondary{
                &{flex-direction: column;gap: 10px;}
                input{width: 100%;margin-bottom: 10px;}
            }
            
            label{
                &{display: block;font-size: 20px;font-weight: bold;}
                &::after{content:":"}
            }
        }            
    }

    .btn_primary{
        @media (max-width: 600px) {}
        @media (min-width: 600px) {margin-inline-start: auto;display: block;}
    }
}
</style>