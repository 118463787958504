<template>
  <div class="paddme">
     <Popup v-if="showDialog" v-model="showDialog">
      <template #title> האם לבטל את התור? </template>
      <template #actions>
        <button class="btn" @click="showDialog=false">סגירה</button>
        <button style="background:red;margin:0 10px" class="btn red" @click="cancel_apt()">ביטול</button>
      </template>
    </Popup>
    <h1 class="main_title">
      <div v-text="'היי ' + get_me().display_name"></div>
    </h1>
    <div style="font-weight:100;" >{{get_word('hello_text')}}</div>
  </div>
  <div id="prev_orders" class="box" v-if="loaded && orders.length > 0" style="margin-bottom: 20px;">
    <div class="line"> 
        <i class="fas fa-swimming-pool"></i>
        <div class="content">
          <h2>{{get_word('my_orders')}} </h2>
          <ul>
            <li v-for="item in orders" :key="item.id">
              <div class="ord_wrapper">
                <div class="text">
                  <div class="inner">
                    <div> {{item.mikve_data.name}}</div>
                    <span>{{item.apt_time}} </span>
                  </div>
                  <div><a class="btn_white btn small_btn" @click="showDialog = true;dalete_id = item.id">{{get_word('cancel')}} </a></div>
                </div>   
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
		<payForOrder v-if="isPayOrd" @close="closePopUp()" :paymentId="paymentId" />

    <!-- <div v-if="!showNewApt" style="text-align: center;">
      <a class="btn" @click="showNewApt=true; scrollDown()"><i class="fas fa-plus"></i> הזמיני תור נוסף</a>
    </div> -->

    <div id="home" class="box" v-if="loaded && showNewApt" style="margin-bottom: 80px;">
      <div class="line" >
        <i class="fas fa-map-marker-alt"></i>
        <div class="content">
          <div  class="fav_mikve">
            <h2 class="mikve_title" v-html="mikve_title"></h2>
            <div style="display: flex; gap:10px;justify-content: center;">
              <button class="btn_white" v-html="get_word('choose_office')" @click="isChangeMikve = true; "></button>
            </div>
          </div>
          <mikveList v-if="isChangeMikve" @change="get_prices()" @close="isChangeMikve = false" v-model:mikveId="orderData.selectedMikve" v-model:mikveName="mikveName" />
        </div>
      </div>
      <div class="line">
        <i class="fas fa-table"></i>
        <div class="content">
          <div id="date_pick_wrapper">
            <h2 v-html="get_word('choose_date')"></h2>
            <!-- :max-date="Date.now() + (6.048e+8*max_dates_in_weeks)" -->
             <VueDatePicker  v-model="orderData.selectedDate" auto-apply format="dd/MM/yyyy" @update:model-value="onSelectDate" week-start="0"  :min-date="new Date()"  :day-names="['א', 'ב', 'ג', 'ד', 'ה', 'ו', 'ז']"  locale="he" cancelText="ביטול" selectText="בחרי" ></VueDatePicker>
          </div>
        </div>
      </div>
      
     
      <button class="btn" id="big_btn" @click="go_to_order">הזמנת תור</button>

    </div>
  </template>
  
  <script>
    import Popup from "@/views/popup.vue"

  import mikveList from '@/views/mikveList.vue';
  import payForOrder from "@/views/payForOrder.vue";
  export default {
    components:{
      mikveList,
      payForOrder,
      Popup
          },
    
    data() {
  
      return {
        orders: [],
        prices: [],
        types_allow: [],
        unpaid_orders: [],
        unpaid_shop_orders: [],
        types: this.$store.order_types,
        orderData: {
          selectedMikve: '',
          selectedDate: new Date().toISOString().slice(0,10),
          orderType: 1
        },
        paymentId:0,
        mikveName:'',
        max_dates_in_weeks:2,
        apt_length:30,
        isPayOrd:false,
        dalete_id:0,
        showDialog:false,
        show:true,
        loaded:false,
        isChangeMikve: false,
        selectedMikveDetails: {},
        mikves: [],
        selectedMikveDates: [],
        showNewApt:true
      
      }
    },
    methods: {
      isExistAllow(item){
        for(let z in this.types_allow)
          {
            if(this.types_allow[z] == item.id) return true
          }
          return false;
      },
      closePopUp() {
        this.isPayOrd = false;
        this.updateDate();
      },
      pay_for_order(order_id,shop = false) {
      if(shop !== false)
      {
        this.api(
          { action: "pay_for_order", data: { order_id: order_id } },
          (data) => {
            if (+data.data > 1) {
              this.paymentId = +data.data;
              this.isPayOrd = true;
            }
          }
        );
      }
      else{
        this.api(
				{ action: "pay_for_shop_order", data: { order_id: order_id } },
				(data) => {
					if (+data.data > 1) {
						this.paymentId = +data.data;
						this.isPayOrd = true;
					}
				}
			);
      }
		},
      scrollDown(){
        setTimeout(() => {
          document.getElementById('home').scrollIntoView({ behavior: "smooth" });
        }, 100);
      },
      onSelectMikve(selectedMikve) {
        this.api({action: 'mikve/get',data: { id: selectedMikve.id } }, (data)=>{
          this.selectedMikveDetails = data.data;
          this.getDates();
          this.get_prices();
        });
      },
      cancel_apt() {
        this.showDialog = false;
        this.api({action: 'delete_apt',data: { id: this.dalete_id }}, ()=>{
          this.dalete_id = 0;
          this.$router.go();
        });
      },
      
      get_prices() {
        this.api({action: 'get_mikve_data',data: { mikve_id: this.orderData.selectedMikve } }, (data)=>{
          this.prices = data.data.prices;
          this.types_allow = data.data.types_allow;
          if(+data.data.max_dates_in_weeks > 0)
          {
              this.max_dates_in_weeks = data.data.max_dates_in_weeks;
          }
          
        });
      },
      foucs(){
          this.$refs["date_input"].foucs();
      },
      onSelectDate(event){
        console.log(event)
      },
      getDates() {
        this.api({action: 'get_dates' }, (data)=>{
          this.selectedMikveDates = data.data;
          this.show = true;
        });
      },
      go_to_order() {
        if(this.orderData.selectedMikve == '')
        {
          this.alert(this.get_word('choose_department'));
          return false;
        }
        if(this.orderData.orderType == 2)
        {
          this.$router.push(`/mikve/${this.orderData.selectedMikve}/${this.orderData.selectedDate}/${this.orderData.orderType}+${this.apt_length}`)
        }
        else{
            this.$router.push(`/mikve/${this.orderData.selectedMikve}/${this.orderData.selectedDate}/${this.orderData.orderType}`)
        }
        
      }
    },
    async mounted() {
      this.orderData.selectedMikve = this.isNull(this.$store.user.mikve) ? '' : this.$store.user.mikve.id;
      this.mikveName = this.isNull(this.$store.user.mikve) ? '' : this.$store.user.mikve.name;
   

      this.api({ action: 'get_orders'}, (data)=>{
        this.orders = data.data.orders;

        this.unpaid_orders = data.data.unpaid_orders;
        this.unpaid_shop_orders = data.data.unpaid_shop_orders;


        if (data.data.orders && data.data.orders.length > 0) this.showNewApt=false;

        this.get_prices();

        
      });
    
        this.loaded = true;

    },
    computed: {
      
        words() {
          return this.$store.state.words
        },
        mikve_title() {
          return this.get_word('mikve_title').replace('mikve', "")+'<div>'+this.mikveName + '</div>';
        },
    },
  }
  </script>
  
<style lang="scss">
  .links{   display: flex;   justify-content: flex-start;   gap: 10px;align-items: center;
        a{text-decoration: none;display: flex;justify-content: center;align-items: center; font-size: 14px !important;   height: auto;    border-color: #127dbb !important;color: #127dbb !important;height: 30px;width: 30px;}
        .svg-inline--fa {color: #127dbb !important;font-size: 16px;}
    }
  .order_wrapper_title{font-weight: 600;}
  .order_wrapper{
    display: flex;justify-content: flex-start;flex-direction: column;    gap: 1px;;padding: 5px 16px 5px 5px;margin-bottom: 10px;
      border-radius: 10px;list-style-type: disc !important;
      li{margin-bottom: 0 !important;padding-bottom: 0 !important;border-bottom: none !important;}
      .is_paid{
        .red{font-weight: 700;color: red;}
        .green{font-weight: 700;color:#58c343;}
      }
    }
  #unpaid{
    &{background:  #ff0000ad;color: #fff;}
    h2,.line i, .line svg{color: #fff;}
    .line{border-bottom:none}
    ul li{border-bottom: 1px solid rgba(0, 0, 0, 0.1);}
    ul li:last-of-type{border-bottom: none;}
    .ord_wrapper{align-items: center;}
    .btn{height: auto;padding: 0 8px;font-size: 16px;color: rgba(255, 0, 0, 1); font-weight: 600;background:#fff;}
  }
#prev_orders {
  .fa-comments{color: #fff;}
  .btn{height: auto !important;padding: 5px 10px;line-height: 1;}
  .line {padding-bottom: 0px; border: none;;
      .ord_wrapper{flex-direction: column;}
      .btn_wrapper{gap: 5px;display: flex;flex: 1;flex-direction: row;
        .btn,.btn_white{font-size: 16px;line-height: 1;    flex: 1;height: auto;}
      }
      .text{flex: 2; display: flex; justify-content: space-between;align-items: center;}
  }
  ul li { border-bottom: 1px solid rgba(0, 0, 0, 0.1); font-size: 16px; padding-bottom: 15px; margin-bottom: 15px;
    &:last-child { border-bottom: none; padding: 0px; margin: 0px;}
  }
}

.apt_length{
  span{font-weight: bold;}
  input{margin-left: 10px;}
}
.main_title { font-size: 24px; }

.mikve_title { margin-bottom: 10px;
  div { font-size: 18px;font-weight: 200;}
}
.ord_wrapper{display: flex;justify-content: space-between;gap: 5px;
  .purchase{    font-weight: 600; font-size: 17px;text-decoration: underline;}
}
.item{padding: 20px 0;}
.content{flex-grow: 1;}
  #home { padding-bottom: 60px;

    #mikve_select_wrapper{
      &{margin: 10px 0;}
    }

    #date_pick_wrapper {
      &{display: flex;align-items: flex-start;flex-direction: column;}
    }

    .select2-container{
      &{width: 100%!important;height: 48px;}
      *{height: 48px;}
    }
  }
  .radio_opts { list-style: none; width: 100%;
    li { display: flex; border-bottom: 1px solid #eee; line-height: 50px; padding-right: 15px;justify-content: space-between;padding: 0 10px;
      &.active { background: var(--color_dom); color: #fff;}
    }

  }
  .small_btn{padding: 5px !important;font-size: 16px !important;}
</style>